.footer {
  display: flex;
  align-items: center; 
  position: relative; 
  margin-top: 10%;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem; 
  color: white;
}
.footerContainer{

  margin: 3rem 5rem 0 5rem;
  justify-content: center;
  /* justify-content: center;
  align-items: center; */
  text-align: left !important;
}
.footerTitle{
  font-size: 1.1rem;
  justify-content: left !important;
  align-items: left !important;
  text-align: left !important;
  margin-bottom: 0.7rem;
  color: #06113C;
  line-height: 28px;
  font-weight: 800;
}
.footerContent{
  margin-bottom: 1.2rem;
  color: #F4313F;
  font-size: 0.9rem;
}
.footerContentTitle{
  color: #F4313F;
  line-height: 28px;
  font-weight: 900;
}
.f-icons {
  display: flex; 
}

.footer-copyright {
  background-color: #EFEFEF;
  color: #06113C;
  padding: 15px 30px;
  text-align: center;
  position: relative;
  bottom: -64px;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
  color: #06113C;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #06113C;
  text-decoration: none;
} 

.map{
  max-width: 450px;
  margin-top: -3%;
}

.footerCol{
 max-width: 280px;
 min-width: 150px;
}

.wave{
  background-color: #EFEFEF;
  height: 20rem;
  width: 100%;
}

@media screen and (max-width: 480px){
  .f-content{
    transform: scale(.8);
  }
  .footer {
    margin-top: 40%;
  }
  .wave{
    height: 37rem;
  }
  /* .map{
    transform: scale(.7);
    margin-left: -80%;
  } */
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
  .f-content {
    margin-top: 0rem; 
  }
  .footerCol{
    max-width: 280px;
    min-width: 250px;
   }
   
}
@media screen and (min-width: 320px) and (max-width: 479px){
  .map{
    transform: scale(.4);
    margin-bottom: 1.5rem;
  }
  .wave{
    height: 43rem !important;
  }
  .f-content {
    margin-top: -3%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 590px){
  .map{
    transform: scale(.5);
    max-width: 550px;
    margin-bottom: 1.5rem;
  }
  .wave{
    height: 43rem !important;
  }
  .f-content {
    margin-top: -3%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}
@media screen and (min-width: 591px) and (max-width: 779px){
  .map{
    transform: scale(.5);
    max-width: 550px;
    margin-bottom: 1.5rem;
  }
  .wave{
    height: 39rem !important;
  }
  .f-content {
    margin-top: -3%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}
@media screen and (max-width: 780px){
  .map{
    transform: scale(1.0);
    max-width: 550px;
    margin-bottom: 1.5rem;
  }
  .wave{
    height: 36rem;
  }
  .f-content {
    margin-top: -3%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}
@media screen and (min-width: 781px) and (max-width: 1080px){
  .map{
    max-width: 550px;
    margin-bottom: 1.5rem;
  }
  .wave{
    height: 30rem !important;
  }
  .f-content {
    margin-top: -3%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}
@media screen and (max-width: 1440px){
  .wave{
    height: 24rem;
  }
  .f-content {
    margin-top: 0%; 
  }
  .footerContainer{
    margin: 0rem 5rem 0 5rem;
  }
}