.services {
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 30rem; 
  /* scroll view */ 
  padding: 0 10% 0 10%;

}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;

  /* gap: 20px; */
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Roboto Slab";
}
.awesome > :nth-child(2) {
  color: var(--red);
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Roboto Slab";
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 20px !important;
  font-weight: 400;
  line-height: 34px;
  margin-top: 1rem;
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}
/* right sided */
.cards {
  position: relative;
}
.cards > * {
  position: absolute;
  margin-top: 2.4rem;
}

.cards > :nth-child(1)  >.card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  width: 7.5rem;
}
.cards > :nth-child(2) > .card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  width:12rem; 
}
.cards > :nth-child(3) > .card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  width:14.5rem;
  padding-bottom: 35px;
}
.cards > :nth-child(4) > .card >img {
  transform: scale(0.6);
  margin-bottom: -1.8rem;
  width:6rem;
}
.cards > :nth-child(5) > .card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  
  width:7.6rem;
}
.cards > :nth-child(6) > .card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  width:7.5rem;
}
.cards > :nth-child(7) > .card >img {
  transform: scale(0.6);
  margin-bottom: -2rem;
  width:10rem;
  padding-bottom: 15px;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width: 320px) {
  .cards > :nth-child(1)  {
    top: 0rem;
    margin-left: -1.5rem;
  }
  .cards > :nth-child(2)  {
    margin-top: 16rem;
    margin-left: -17.5rem !important;
  }
  .cards > :nth-child(3)  {
    margin-top: 30rem;
    margin-left: -33.5rem !important;
  }
  .cards > :nth-child(4)  {
    margin-top: 40rem;
    margin-left: -1.5rem;
  }
  .cards > :nth-child(5)  {
    margin-top: 26rem;
    margin-left: -1.5rem !important;
  }
  .cards > :nth-child(6)  {
    margin-top: 40rem;
    margin-left: -17.5rem !important;
  }
  .cards > :nth-child(7)  {
    margin-top: 54rem;
    margin-left: -33.5rem !important;
  }
}

@media screen and (max-width: 480px) {
  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
    padding: 0.5rem 2.5rem;
  }

  .cards {
    /* display: flex;
    flex-direction: column;
    gap: 17rem; */
  }
  .cards > * {
    /* position: static; */
  }

  .cards > :nth-child(1)  {
    top: 0rem;
  }
  .cards > :nth-child(2)  {
    margin-top: 16rem;
    margin-left: -16rem;
  }
  .cards > :nth-child(3)  {
    margin-top: 30rem;
    margin-left: -32rem;
  }
  .cards > :nth-child(4)  {
    margin-top: 40rem;
  }
  .cards > :nth-child(5)  {
    margin-top: 26rem;
    
  }
  .cards > :nth-child(6)  {
    margin-top: 40rem;
    margin-left: -16rem;
  }
  .cards > :nth-child(7)  {
    margin-top: 54rem;
    margin-left: -32rem;
  }


  .awesome > :nth-child(1) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(2) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(3) {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 86rem;
    padding: 0;
    padding: 0.5rem 2.5rem;
  }

  .cards > :nth-child(1)  {
    top: 0rem;
  }
  .cards > :nth-child(2)  {
    top: 0rem;
    margin-left: 4rem;
  }
  .cards > :nth-child(3)  {
    margin-top: 16rem;
    margin-left: -32rem;
  }
  .cards > :nth-child(4)  {
    margin-top: -2rem;
    margin-left: 36rem;
  }
  .cards > :nth-child(5)  {
    margin-top: 13rem;
    margin-left: 0rem;
    
  }
  .cards > :nth-child(6)  {
    margin-top: 13rem;
    margin-left: 4rem;
  }
  .cards > :nth-child(7)  {
    margin-top: 28rem;
    margin-left: -32rem;
  }


  .awesome > :nth-child(1) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(2) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(3) {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .services { 
    height: 50rem;  
  
  }
  .cards > :nth-child(1)  {
    top: 5rem;
  }
  .cards > :nth-child(2)  {
    top: 20rem;
    margin-left: -47.5rem;
  }
  .cards > :nth-child(3)  {
    top: 20rem;
    margin-left: -47.7rem;
  }
  .cards > :nth-child(4)  {
    margin-top: 3.4rem;
    margin-left: 16rem;
  }
  .cards > :nth-child(5)  {
    margin-top: 18rem; 
  }
  .cards > :nth-child(6)  {
    margin-top: 18rem;
    margin-left: -31.7rem;
  }
  .cards > :nth-child(7)  {
    margin-top: 18rem;
    margin-left: -63.5rem;
  }


  
}
 
@media screen and (min-width: 1025px) and (max-width: 1640px) {
  .services { 
    height: 30rem;  
  
  }
  .cards > :nth-child(1)  {
    top: 5rem;
    margin-left: 4rem;

  }
  .cards > :nth-child(2)  {
    top: 20rem;
    margin-left: -35.5rem;
  }
  .cards > :nth-child(3)  {
    top: 20rem;
    margin-left: -39.7rem;
  }
  .cards > :nth-child(4)  {
    margin-top: 3.7rem;
    margin-left: 20rem;
  }
  .cards > :nth-child(5)  {
    margin-top: -11.3rem; 
    margin-left: 16rem;

  }
  .cards > :nth-child(6)  {
    margin-top: 3.7rem; 
    margin-left: 0rem;
  }
  .cards > :nth-child(7)  {
    margin-top: 3.7rem;
    margin-left: -63.5rem;
  }


  
}


