.Intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
  padding: 5% 10% 0 10%; 
}

/* left side styling */
.testLogo{
  /* width: 52rem; */
  /* margin-left: -10%; */
  margin-top: -15%;
}
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: 800;
  font-size: 2.5rem;
  font-family: "Roboto Slab";
}
.i-name > :nth-child(2) {
  color: var(--red);
  font-weight: 800;
  font-size: 2.5rem;
  font-family: "Roboto Slab";
}
.i-name > :nth-child(3) {
  font-weight: 400;
  font-size: 22px !important;
  color: var(--gray);
  margin-top: 10px;
}
.i-button {
  width: 6rem;
  height: 2rem;
}
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}
/* right side styling */
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.i-right > :nth-child(2) {
  top: -4.6rem;
  transform: scale(0.67);
  left: -3rem;
}
.i-right > :nth-child(3) {
  transform: scale(1.4);
  left: 28%;
}
.i-right > :nth-child(4) {
  transform: scale(0.62);
  top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;
}

.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 64rem;
    padding: 0.5rem 2.5rem;
  }

  .i-right {
    transform: scale(0.8);
    left: -3rem;
    margin-top: -4rem;
  }
  .i-right .blur {
    display: none;
  }
  .floating-div:nth-of-type(1){
    top: -7rem !important;;
  }
  .testLogo{
    width: 30rem;
    margin-left: -25%;
    margin-top: -6%;
  }
  .i-name > :nth-child(1) {
    font-size: 2.3rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2.3rem;
  }
  .i-name > :nth-child(3) {
    font-size: 20px !important;
  }
  .floating-div-one > .floatingDiv {
    max-width: 8rem !important;
    height: 3rem;
  }
  .floating-div-one > .floatingDiv > span {
    font-size: 12px;
  }
  .floating-div-one > .floatingDiv > img {
    width: 4rem;
  }
}
@media screen and (max-width: 320px) {
  .testLogo{
    width: 25rem;
    margin-left: 0%;
    margin-top: 10%;
  }
  .floating-div-two > .floatingDiv {
    margin-top: -2.2rem;
  }
  .Intro { 
    margin-bottom: -8rem;
  }
}

@media screen and (max-width: 768px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 64rem;
    margin-bottom: -5rem;
  }
  .i-right {
    transform: scale(0.8);
    margin-top: -70%;
  }
  .i-right .blur {
    display: none;
  }
  .floating-div:nth-of-type(1){
    top: -7rem !important;;
  }
  .testLogo{
    margin-left: -5%;
  }
}
@media screen and (min-width: 321px) and (max-width: 480px){
  .i-right {
    /* margin-left: -10%; */
    transform: scale(0.8);
  }
  .Intro { 
    margin-bottom: -8rem;
  }
}
@media screen and (min-width: 481px) and (max-width: 566px){
  .i-right {
    margin-left: -25%;
    transform: scale(0.7);
  }
}
@media screen and (min-width: 567px) and (max-width: 664px){
  .i-right {
    /* margin-top: -20%; */
    margin-left: -10%;
    transform: scale(0.7);
  }
}
@media screen and (min-width: 769px) and (max-width: 918px){
  .i-name > :nth-child(1) {
    font-size: 2rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2rem;
  }
  .i-name > :nth-child(3) {
    font-size: 18px !important;
  }
  .i-right {
    margin-top: -20%;
    transform: scale(0.4);
  }
  .Intro { 
    margin-bottom: -6rem;
  }
}

@media screen and (min-width: 918px) and (max-width: 1040px){
  .i-name > :nth-child(1) {
    font-size: 2.5rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2.5rem;
  }
  .i-name > :nth-child(3) {
    font-size: 20px !important;
  }
  .i-right {
    margin-top: -6%;
    transform: scale(0.5);
  }
}
@media screen and (min-width: 1040px) and (max-width: 1244px){
  .i-name > :nth-child(1) {
    font-size: 2.5rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2.5rem;
  }
  .i-name > :nth-child(3) {
    font-size: 20px !important;
  }
  .i-right {
    margin-top: -7%;
    transform: scale(0.6);
  }
}
@media screen and (min-width: 1245px) and (max-width: 1416px){
  /* .testLogo{
    transform: scale(.5);
  } */
  .i-right {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1440px){
  .Intro { 
    margin-bottom: 6rem;
  }
}