/* .portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  padding: 0 10% 0 10%;
} 
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: var(--red);
  font-size: 2.5rem;
  font-weight: bold;
}

.portfolio > :nth-child(3) {
  color: var(--gray);
  font-size: 1rem !important;
  text-align:center ;
}
 
.portfolio .swiper{
    overflow: visible!important;
}

.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}

.portfolio-slider .swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}

@media screen and (max-width: 480px) {
  .portfolio span:nth-of-type(1) {
    font-size: 2rem;
  }
  
  .portfolio span:nth-of-type(2) {
    font-size: 2rem;
  }
  .portfolio > :nth-child(3) {
    font-size: 16px !important;
    text-align:left ;
  }

  .portfolio-slider { 
    width: 100%;
  }
  .portfolio img {
    width: 12rem;
  }
  .portfolio {
    margin-top: -35%;
    padding: 0.5rem 2.5rem;
  }
} */
.portfolioContent{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  padding: 0 10% 0 10%;
  
}
.portfolioContent > :nth-child(1) {
  font-family: "Roboto Slab";
  font-weight: 800 !important;
}
.portfolioContent > :nth-child(2) {
  font-family: "Roboto Slab";
  font-weight: 800 !important;
}

.portfolioContent > :nth-child(3) {
  color: var(--gray);
  font-size: 20px !important;
  font-weight: 400;
  line-height: 34px;
  text-align:center ;
}
.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

/*heading*/

.portfolioContent span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolioContent span:nth-of-type(2) {
  color: var(--red);
  font-size: 2.5rem;
  font-weight: bold;
}


.portfolio .swiper{
  /*bütün slaytlar bir ekranda görünebilir olsun diye*/
  overflow: visible!important;
}
.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}
.portfolio-slider .swiper-slide{
  width: 20rem;
}

.portfolio img {
 width: 400px;
 height: 120px;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
border-radius: 19px;
}

@media (min-width: 320px) and (max-width: 479px) {
  .portfolio img {
    transform: scale(0.7);  
  }
  .portfolio-slider {
    width: 120%; 
  }
  .portfolioContent span:nth-of-type(1) {
    font-size: 2rem;
  }
  
  .portfolioContent span:nth-of-type(2) {
    font-size: 2rem;
  }
  .portfolioContent > :nth-child(3) {
    font-size: 16px !important;
    text-align:left ;
  }
  .portfolioContent {
    margin-top: -35%;
    padding: 0.5rem 2.5rem;
  }
  }

  @media (min-width: 480px) and (max-width: 599px){
    .portfolio img {
      transform: scale(0.8);  
    }
    .portfolio-slider {
      width: 100%;
    }
  }
  @media (min-width: 600px) and (max-width: 799px){
    .portfolio img {
      transform: scale(1);  
    }
    .portfolio-slider {
      width: 150%;
    }
  }
  @media (min-width: 800px) and (max-width: 1023px){
    /* .portfolio img {
      transform: scale();  
    } */
    .portfolio-slider {
      width: 100%;
    }
  }
  @media (min-width: 1024px) and (max-width:1199px){
    /* .portfolio img {
      transform: scale(0.8);  
    } */
    .portfolio-slider {
      width: 100%;
    }
  }
  @media (min-width:1200px) and (max-width: 1439px){
    /* .portfolio img {
      transform: scale(0.9);  
    } */
    .portfolio-slider {
      width: 100%;
    }
  }
  
  @media (min-width:1440px)    {
    .portfolio img {
      transform: scale(1.5);  
    }
    .portfolio-slider {
      width: 70%;
    }
  }
