.works { 
  display: flex;
  height: 90vh;
  margin-top: 2rem;
  margin-bottom: 1rem;
  /* scroll */
  align-items: center;
  padding: 3% 10% 0 10%;

}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Roboto Slab";
}
.awesome > :nth-child(2) {
  color: var(--red);
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Roboto Slab";
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 20px !important;
  font-weight: 400;
  line-height: 34px;
  margin-top: 1rem;
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 4rem;
}

/* right side */
.w-right {
  position: relative;
}

.w-mainCircle {
  left: 9rem;
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  top: 2rem;
  background: white;
  /* darkMode */
  z-index: 2;
}
.w-mainCircle > * {
  position: absolute;
}
.w-mainCircle > :nth-child(1) {
  top: -3rem;
  left: 5.4rem;
}
.w-mainCircle > :nth-child(2) {
  left: -1.3rem;
  top: 0.1rem;
}
.w-mainCircle > :nth-child(3) {
  left: 6rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(4) {
  left: 12.6rem;
  top: 0.1rem;
}
.w-mainCircle > :nth-child(5) {
  left: -2.7rem;
  top: 7.3rem;
}
.w-mainCircle > :nth-child(6) {
  left: 13.4rem;
  top: 7.4rem;
}
.w-mainCircle > :nth-child(7) {
  left: 9rem;
  top: 13rem;
}
.w-mainCircle > :nth-child(8) {
  left: 1.4rem;
  top: 13rem;
}
.w-mainCircle > :nth-child(1)>img {
   
  width: 8rem;
}
.w-mainCircle > :nth-child(2)>img {
  width: 8rem;
}
.w-mainCircle > :nth-child(3)>img {
  width: 6rem;
}
.w-mainCircle > :nth-child(4) >img{
  width: 7.5rem;
}
.w-mainCircle > :nth-child(5) >img{
  width: 11rem;
}
.w-mainCircle > :nth-child(6) >img{
  width: 7.5rem;
}
.w-mainCircle > :nth-child(7) >img{
  width: 8rem;
}
.w-mainCircle > :nth-child(8) >img{
  width: 5.3rem;
}
.w-secCircle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 50%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  box-shadow: var(--smboxShadow);
  background: white;
}

.w-secCircle > img {
  transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  /* darkMode */
  z-index: 1;
}
.blueCircle {
  background: #1949b8;
  left: 18rem;
  top: 0rem;
}
.yellowCircle {
  background: #f5c32c;
  left: 18rem;
  top: 8rem;
}


@media screen and (max-width: 320px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0;
    gap: 5rem;
    margin-top: 52rem;
  }

  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content:start;
    transform: scale(0.7);
  }
  .w-mainCircle{
    position: static;
    width: 15rem;
    height: 15rem;
  }
  .w-mainCircle > :nth-child(1) {
    top: -3rem;
    left: 5rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(2) {
    left: -1.2rem;
    top: -0.5rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(3) {
    left: 5rem;
    top: 5rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(4) {
    left: 11.2rem;
    top: 0.2rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(5) {
    left: -2.7rem;
    top: 6.2rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(6) {
    left: 12rem;
    top: 7rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(7) {
    left: 7.5rem;
    top: 11.7rem;
    width: 5.8rem;
  }
  .w-mainCircle > :nth-child(8) {
    left: 1rem;
    top: 11.5rem;
    width: 5.8rem;
  }
  .w-backCircle{
    left: 6rem!important;
  }
  .blueCircle {
    background: #1949b8;
    left: 18rem;
    top: -2.5rem;
  }
  .yellowCircle {
    background: #f5c32c;
    left: 18rem;
    top: 6rem;
  }
 

}
@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0;
    gap: 5rem;
    margin-top: 52rem;
    padding: 0.5rem 2.5rem;
  }

  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content:start;
    transform: scale(0.7);
    margin-top: -20%;
  }
  .w-mainCircle{
    position: static;
  }
  .w-backCircle{
    left: 8rem!important;
    margin-top: -10%;
  }

  .awesome > :nth-child(1) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(2) {
    font-size: 2.1rem !important;
  }
  .awesome > :nth-child(3) {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 481px) and (max-width :768px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0;
    gap: 5rem; 
    padding: 0.5rem 2.5rem;
  }
  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content:start;
    transform: scale(0.9);
  }
  .w-mainCircle{
    position: static;
  }
  .w-backCircle{
    left: 8rem!important;
  }
}
@media screen and (min-width: 769px) and (max-width :1024px) {
  
  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content:start;
    transform: scale(0.8);
    margin-left: 1rem;
  }
  .w-mainCircle{
    position: static;
  }
  .w-backCircle{
    left: 8rem!important;
  }
}
@media screen and (min-width: 1025px) and (max-width :1440px) {
  
  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content:start;
    transform: scale(1);
    margin-left: 3rem;
  }
  .w-mainCircle{
    position: static;
  }
  .w-backCircle{
    left: 8rem!important;
  }
}
