:root
{
  --yellow: #F5C32C;
    --red : #F4313F;
    --black : #041A72;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --redCard: rgba(233, 68, 56, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    /* padding: 0.5rem 10.5rem; */
    /* padding-left: 10%; */
    overflow: hidden;
    color: var(--black);    
    /* background-color: #EEEEEE; */
}

.button {
    background: linear-gradient(120deg, #ee1e17 .71%, #d15c54 99.36%);
    box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
  }
  
  .button:hover {
    background: white;
    cursor: pointer;
    border: 1px solid var(--red);
    color: var(--red);
  }
  /* @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 2.5rem;

    }
  } */

  

  