.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top: 0;
  padding: 0 10% 0 10%;
}
.logo{
  width: 6rem;
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
}

.n-list > ul :hover {
  color: var(--red) !important;
  cursor: pointer !important;
}

.n-button {
  flex: 2;
}

@media screen and (max-width: 480px) {
  .n-list {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .logo{
    width: 9rem;
  }
}
 